/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, ContactForm, Divider } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Poptávkový formulář"}>
        <SiteHeader />

        <Column className="--center pb--30 pt--30" name={"uvod"} style={{"backgroundColor":"var(--color-custom-1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" animS={"4"} style={{"maxWidth":""}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s4 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"4"} style={{"maxWidth":""}}>
              
              <Title className="title-box fs--36 title-box--invert" style={{"maxWidth":""}} content={"Nezávazný poptávkový formulář"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--10" name={"pqy6zxzu3qd"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1000}} columns={"1"}>
            
            <ColumnWrapper className="pt--10" style={{"maxWidth":800}}>
              
              <Text className="text-box" content={"Po odeslání formuláře vás budeme co nejdříve <span style=\"font-weight: bold;\">kontaktovat</span>, abychom <span style=\"font-weight: bold;\">domluvili detaily<br></span>a mohli vám poskytnout co nejpřesnější informace o výsledné ceně.<br>Těšíme se na spolupráci.\n\n"}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/t/17432/d51ba375498b4eacb7ee86608cae3b7d_s=660x_.png"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/17432/d51ba375498b4eacb7ee86608cae3b7d_s=350x_.png 350w, https://cdn.swbpg.com/t/17432/d51ba375498b4eacb7ee86608cae3b7d_s=660x_.png 660w, https://cdn.swbpg.com/t/17432/d51ba375498b4eacb7ee86608cae3b7d_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--40" name={"y6hk6q6v0ps"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1000}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <ContactForm className="--shape2 --style1 w--900" style={{"maxWidth":966}} action={"/contact"} fields={[{"name":"Jméno a příjmení","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"O úklid jakého prostoru máte zájem?","type":"select","options":["Úklid domácností","Úklid kanceláří","Úklid business objektů","Úklid společenských prostor","Úklid restaurace","Úklid ordinací","Úklid ubytovacích zařízení","Úklid průmyslových hal","Úklid něčeho jiného","Služba hospodyňka"],"required":true,"placeholder":"vyberte"},{"name":"Typ úklidu","type":"select","options":["Jednorázový úklid","Pravidelný úklid","Generální úklid","Parní čištění","Parní čištění podlah, dlažeb apod.","Sezónní úklid","Služba hospodyňka"],"required":true,"placeholder":"vyberte"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat poptávku","type":"submit","align":"center"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"paticka"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Divider className="mt--20">
              </Divider>

              <Title className="title-box title-box--center fs--36" content={"<span style=\"font-weight: bold;\"><br>Kontakt:</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--center fs--20" content={"<span style=\"color: var(--color-supplementary);\"><span style=\"font-weight: bold;\">+420</span> <span style=\"font-weight: bold;\">734 148 215</span></span><br>"}>
              </Text>

              <Text className="text-box text-box--center fs--20" style={{"marginTop":0}} content={"<a href=\"mailto:uklidnaklik@gmail.com?subject=Popt%C3%A1vka+z+webu\" style=\"font-weight: bold; color: var(--color-supplementary);\">uklidnaklik@gmail.com</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}